import React, { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { LuMessagesSquare } from "react-icons/lu";
import { TfiHeadphoneAlt } from "react-icons/tfi";

import { ContactForm } from "@components/ContactForm";

export const HelpForm = ({ userData }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {!isOpen && (
        <button
          onClick={() => setIsOpen(true)}
          role="button"
          aria-label="Need Help?"
          className="fixed right-6 bottom-6 z-[2001] bg-gradient-to-t from-tollguru-primary to-tollguru-secondary text-xl text-white p-2 rounded-full shadow-lg flex items-center gap-2">
          <TfiHeadphoneAlt className="text-3xl" />
        </button>
      )}
      {isOpen && (
        <div className="fixed right-6 bottom-6 z-[2001] text-xl cursor-pointer shadow-2xl bg-white rounded-[20px] pb-2">
          <div className="  flex justify-center gap-5 p-4 bg-gradient-to-t from-tollguru-primary to-tollguru-secondary text-white items-center inverted-border-radius rounded-t-[20px]">
            <span className="w-12 h-12 bg-tollguru-secondary rounded-full flex justify-center items-center">
              <LuMessagesSquare className="text-2xl" />
            </span>
            <p className="text-lg" tabIndex={0} aria-label="Leave us a message">Leave us a message</p>
            <ChevronDownIcon
              onClick={() => setIsOpen(false)}
              className="h-8 w-8 p-1 text-white mt-[2px] font-bold absolute -top-10 right-2 bg-gradient-to-t from-tollguru-primary to-tollguru-secondary rounded-full"
            />
          </div>
          <div className="p-6 py-3 pb-0 max-h-[60vh] h-full min-h-min overflow-y-auto show-custom-scrollbar rounded-[20px] bg-white">
            <ContactForm
              context="helpForm"
              onClose={() => setIsOpen(false)}
              userData={userData}
            />
          </div>
        </div>
      )}
    </>
  );
};
